/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionReducerMap } from '@ngrx/store';
import * as shared from './shared/store/reducers';

export interface AppState {
  user: shared.State;
  geometry: shared.GeometryState;
  dynamic: shared.DynamicState;
}

export const appReducers: ActionReducerMap<AppState> = {
  user: shared.userReducer,
  geometry: shared.geometryReducer,
  dynamic: shared.dynamicReducer,
};
