/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/core/generics/base-service.service';
import { Stretch } from '..';

const controller = 'Tramo';

@Injectable({
  providedIn: 'root',
})
export class StretchService {
  constructor(private base: BaseService) {}

  getAll = (): Observable<Stretch[]> => {
    const parametersUrlArray: string[] = [];
    let parametersUrl = '';

    if (parametersUrlArray.length > 0) {
      parametersUrl = `&${parametersUrlArray.join('&')}`;
    }

    return this.base.get<any>(`${controller}/GetAll`).pipe(
      map((k: any[]) => {
        return k.map((j) => {
          const cast: Stretch = {
            id: j.id,
            name: j.nombre,
            roadId: j.idCtra,
          };
          return cast;
        });
      })
    );
  };
}
