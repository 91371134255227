/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginModalComponent } from 'src/app/shared/components/login-modal/login-modal.component';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TokenService } from 'src/app/shared/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  isShowingOverlayLogin = false;
  constructor(
    private router: Router,
    private auth: AuthService,
    private token: TokenService,
    private notification: NotificationService,
    private modalService: ModalService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.isAuthenticated() && !req.url.endsWith('/connect/token') && !req.url.endsWith('.json')) {
      if (this.token.isExpired()) {
        // Here is when we going to show the overlay login
        if (!this.isShowingOverlayLogin) {
          const login = this.modalService.openComponent(LoginModalComponent);
          login.closed$.subscribe(() => {
            this.isShowingOverlayLogin = false;
          });
          this.isShowingOverlayLogin = true;
        }
        return throwError('Error message');
      } else {
        if (this.token.isCloseToExpire()) {
          this.token.refresh();
        }
      }
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          if (!this.auth.isAuthenticated() || this.token.isExpired()) {
            this.auth.logout();
          } else {
            this.notification.error('error401');
          }
        } else if (err.status === 403) {
          this.notification.error('error401');
        } else {
          this.notification.error('error');
        }
        return throwError(err);
      })
    );
  }
}
