import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { Graph1Component } from './graph1.component';

@NgModule({
  declarations: [Graph1Component],
  imports: [CommonModule, TranslateModule, DxChartModule, DxPieChartModule],
  exports: [Graph1Component],
})
export class Graph1Module {}
