/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Config } from 'src/app/core/modules/config';
import { ConfigService } from 'src/app/core/modules/config/config.service';
import { Authentication, PayLoad, TOKEN_NAME } from '..';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private configuration: Config | undefined;

  constructor(private config: ConfigService, public translate: TranslateService, private http: HttpClient) {
    this.configuration = config.get();
  }

  /**
   * Authenticated user data
   */
  getAuthData = (): Authentication | null => {
    const value = localStorage.getItem(TOKEN_NAME);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  };

  /**
   * Payload of token
   * @returns
   */
  get = (): PayLoad | null => {
    const dataAuthentication = this.getAuthData();

    if (!dataAuthentication) {
      throw new Error('There isn´t authentication data.');
    }
    const decoded = jwtDecode<PayLoad>(dataAuthentication.access_token);
    if (decoded.exp === undefined) {
      return null;
    }

    return decoded;
  };

  /**
   * Refresh token
   * @returns
   */
  refresh = (refresh_token?: string): Observable<Authentication | null> => {
    this.configuration = this.config.get();

    const body = new URLSearchParams();
    let dataAuthentication = this.getAuthData();

    const refreshToken = !refresh_token ? dataAuthentication?.refresh_token : refresh_token;

    if (!refreshToken) {
      throw new Error('There isn´t authentication data.');
    }

    body.set('refresh_token', refreshToken);
    body.set('grant_type', 'refresh_token');
    body.set('client_id', 'api');
    body.set('hostId', this.configuration ? this.configuration.host : '');

    if (!this.configuration) {
      return of(null);
    }

    return this.http.post<Authentication>(`${this.configuration.auth_api_enpoint}/connect/token`, body.toString()).pipe(
      tap({
        next: (m) => {
          if (!dataAuthentication) {
            dataAuthentication = {
              access_token: m.access_token,
              expires_in: m.expires_in,
              refresh_token: refreshToken,
              scope: m.scope,
              token_type: m.token_type,
            };
          }
          dataAuthentication.access_token = m.access_token;
          localStorage.setItem(TOKEN_NAME, JSON.stringify(dataAuthentication));
          const getDataToken = this.get();
          if (getDataToken?.LangCode) {
            this.translate.use(getDataToken.LangCode);
          } else {
            this.translate.use('es-ES');
          }
        },
      })
    );
  };

  /**
   * Expiry date of token
   * @returns
   */
  expirationDate = (): Date | null => {
    const decoded = this.get();
    if (!decoded || decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  };

  /**
   * Is token expired
   * @returns
   */
  isExpired = (): boolean => {
    const dateExpire = this.expirationDate();
    if (!dateExpire) {
      return true;
    }
    const currentDate = new Date();

    return !(dateExpire.valueOf() > currentDate.valueOf());
  };

  /**
   * Is token close to expire
   * @returns
   */
  isCloseToExpire = (): boolean => {
    if (this.isExpired()) {
      return true;
    }

    const dateExpire = this.expirationDate();
    if (!dateExpire) {
      return true;
    }
    const currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() + 900);

    return currentDate.valueOf() > dateExpire.valueOf();
  };
}
