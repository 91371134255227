/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { deepCopy } from 'deep-copy-ts';

@Injectable({
  providedIn: 'root',
})
export class DeepCopyService {
  constructor() {}

  deepCopy<T>(target: T): T {
    return deepCopy(target);
  }
}
