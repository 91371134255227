/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { GeometryService } from '../../services/geometry.service';
import * as geometry from '../actions/geometry.actions';
import { getExtentState, getSimplifiedState } from '../reducers';

@Injectable()
export class GeometryEffects {
  constructor(private actions$: Actions, public geometryService: GeometryService, private store: Store<AppState>) {}

  loadExtent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(geometry.loadedExtent),
      concatLatestFrom(() => this.store.select(getExtentState)),
      filter(([, extent]) => !extent),
      mergeMap(() =>
        this.geometryService.getExtent().pipe(
          map((extent) => geometry.loadExtentSuccess({ extent })),
          catchError((error) => of(geometry.loadExtentError({ payload: error })))
        )
      )
    )
  );

  loadSimplifiedGeometry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(geometry.loadedSimplifiedGeometry),
      concatLatestFrom(() => this.store.select(getSimplifiedState)),
      filter(([, list]) => !list || list.length === 0),
      mergeMap(() => {
        return this.geometryService.getSimplifiedGeometry().pipe(
          map((simplified) => geometry.loadSimplifiedGeometrySuccess({ simplified })),
          catchError((error) => of(geometry.loadSimplifiedGeometrytError({ payload: error })))
        );
      })
    )
  );
}
