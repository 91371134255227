/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as dynamic from '../actions/dynamic.actions';

export interface DynamicState {
  [key: string]: any;
}

export const dynamicInitialState: DynamicState = {};
export const _getDynamicState = createFeatureSelector<DynamicState>('dynamic');
export const getDynamicState = (id: string) => createSelector(_getDynamicState, (state: DynamicState) => state[id]);

const _dynamicReducer = createReducer(
  dynamicInitialState,
  on(dynamic.loadDynmaicSuccess, (state, { prop, data }) => ({
    ...state,
    [prop]: data,
  }))
);

export function dynamicReducer(state: DynamicState | undefined, action: Action): DynamicState {
  return _dynamicReducer(state, action);
}
