import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerComponent } from './spinner/spinner.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private overlayRef: OverlayRef | undefined;
  constructor(private overlay: Overlay) {}

  open = (): void => {
    this.overlayRef = this.createOverlay();
    const filePreviewPortal = new ComponentPortal(SpinnerComponent);

    this.overlayRef.attach(filePreviewPortal);
  };

  close = (): void => {
    this.overlayRef?.detach();
  };

  private createOverlay(): OverlayRef {
    const overlayConfig = this.getOverlayConfig();
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig = (): OverlayConfig => {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  };
}
