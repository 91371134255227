/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CLOUDINARY_CONFIG, NgxPictureModule } from 'ngx-picture';
import { ButtonIconComponent } from './button-icon.component';

@NgModule({
  imports: [CommonModule, NgxPictureModule.forRoot(CLOUDINARY_CONFIG)],
  declarations: [ButtonIconComponent],
  exports: [ButtonIconComponent],
})
export class ButtonIconModule {}
