import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';
import { LayerContainerComponent } from './layer-container/layer-container.component';
import { MenuComponent } from './menu/menu.component';
import { ItemMenuComponent } from './sidebar/item-menu/item-menu.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  // eslint-disable-next-line prettier/prettier
  declarations: [HeaderToolbarComponent, LayerContainerComponent, MenuComponent, SidebarComponent, ItemMenuComponent],
  imports: [CommonModule, TranslateModule],
  // eslint-disable-next-line prettier/prettier
  exports: [HeaderToolbarComponent, LayerContainerComponent, MenuComponent, SidebarComponent],
})
export class TemplateModule {}
