import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DxDropDownBoxModule,
  DxLoadPanelModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxButtonGroupModule } from 'devextreme-angular/ui/button-group';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxoFilterPanelModule } from 'devextreme-angular/ui/nested';
import { DxSparklineModule } from 'devextreme-angular/ui/sparkline';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';

@NgModule({
  imports: [CommonModule],
  exports: [
    DxButtonModule,
    DxButtonGroupModule,
    DxDataGridModule,
    DxSparklineModule,
    DxTabPanelModule,
    DxCheckBoxModule,
    DxTemplateModule,
    DxLoadPanelModule,
    DxDropDownBoxModule,
    DxTextBoxModule,
    DxoFilterPanelModule,
    DxRadioGroupModule,
    DxSelectBoxModule,
  ],
})
export class DevexpressModule {}
