import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigService } from './config.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const configuration = (configService: ConfigService) => () => configService.load();

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ConfigModule {
  static forRoot(): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: configuration,
          deps: [ConfigService],
          multi: true,
        },
      ],
    };
  }
}
