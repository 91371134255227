/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/shared/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class HeadersInterceptorService implements HttpInterceptor {
  /**
   *
   */
  constructor(private token: TokenService) {}
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.token.getAuthData();
    let request = httpRequest;

    if (token) {
      request = httpRequest.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access_token}`,
        }),
      });
    }

    if (request.url.endsWith('/connect/token')) {
      request = request.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      });
    }

    return next.handle(request);
  }
}
