import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { BaseService } from '../core/generics/base-service.service';
import { CardModule } from './components/card/card.module';
import { LoginModalModule } from './components/login-modal/login-modal.module';
import { ModalModule } from './components/modal/modal.module';
import { SpinnerModule } from './components/spinner/spinner.module';
import { ButtonIconModule } from './controls/button-icon/button-icon.module';
import { RsMapModule } from './controls/rs-map/rs-map.module';
import { ViewTabModule } from './controls/view-tab/view-tab.module';
import { AuscultacionGraphModule } from './modules/auscultacion/auscultacion-graph.module';
import { DevexpressModule } from './modules/devexpress.module';
import { Graph1Module } from './modules/graph1/graph1.module';
import { Graph2Module } from './modules/graph2/graph2.module';
import { Graph3Module } from './modules/graph3/graph3.module';
import { AuthService } from './services/auth.service';
import { DeepCopyService } from './services/deep-copy.service';
import { GeometryService } from './services/geometry.service';
import { NotificationService } from './services/notification.service';
import { RoadService } from './services/road.service';
import { StretchService } from './services/stretch.service';
import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';
import { TemplateModule } from './template/template.module';

const MODULES = [
  TemplateModule,
  CardModule,
  ModalModule,
  LoginModalModule,
  SpinnerModule,
  RsMapModule,
  ButtonIconModule,
  ViewTabModule,
  DevexpressModule,
  Graph1Module,
  Graph2Module,
  Graph3Module,
  AuscultacionGraphModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ToastrModule.forRoot()],
  exports: [CommonModule, ...MODULES, TranslateModule, ToastrModule, FontAwesomeModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        BaseService,
        AuthService,
        UserService,
        TokenService,
        NotificationService,
        GeometryService,
        DeepCopyService,
        RoadService,
        StretchService,
      ],
    };
  }
}
