/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, TemplateRef, ViewContainerRef } from '@angular/core';
import { ModalOverlayRef } from './overlay-ref';

export const MODAL_DATA = new InjectionToken<ContainerData>('MODAL_DATA');

export interface ContainerData {
  events: ModalOverlayRef;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  public open(templateContent: TemplateRef<any>, viewContainerRef: ViewContainerRef): OverlayRef {
    const overlayRef = this.createOverlay();
    const templatePortal = new TemplatePortal(templateContent, viewContainerRef);

    overlayRef.attach(templatePortal);
    /*overlayRef.backdropClick().subscribe(() => overlayRef.detach());*/
    return overlayRef;
  }

  public openComponent<R, T>(component: ComponentType<T>, closeOnBackdropClick = false): ModalOverlayRef {
    const overlayRef = this.createOverlay();
    const modalRef = new ModalOverlayRef<R, T>(overlayRef, undefined, closeOnBackdropClick);

    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: MODAL_DATA, useValue: { events: modalRef } }],
    });

    // this.createInjector(modalOverlayRef, this.injector);
    const modalPortal = new ComponentPortal(component, null, injector);
    overlayRef.attach(modalPortal);

    return modalRef;
  }

  private createOverlay(): OverlayRef {
    const overlayConfig = this.getOverlayConfig();
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig = (): OverlayConfig => {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  };
}
