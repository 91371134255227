import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { BaseService } from 'src/app/core/generics/base-service.service';
import { Extent, Geometry, SimplifiedGeometry } from '..';

@Injectable({
  providedIn: 'root',
})
export class GeometryService {
  constructor(private http: HttpClient, private base: BaseService) {}

  /**
   * Geometry of stretch
   * @param filters geometry filters
   * @returns
   */
  get = (filters?: { stretchId: number }): Observable<Geometry[]> => {
    const parametersUrlArray: string[] = [];
    let parametersUrl = '';

    if (filters) {
      if (filters.stretchId) {
        parametersUrlArray.push(`TramoId=${filters.stretchId}`);
      }

      if (parametersUrlArray.length > 0) {
        parametersUrl = `?${parametersUrlArray.join('&')}`;
      }
    }

    return this.base.get<Geometry[]>(`Geometria/GetAll${parametersUrl}`);
  };

  /**
   * Extent of geometry
   * @param filters geometry filters
   * @returns
   */
  getExtent = (filters?: { stretchId?: number; roadId?: number; networkId?: number }): Observable<Extent> => {
    const parametersUrlArray: string[] = [];
    let parametersUrl = '';

    if (filters) {
      if (filters.stretchId) {
        parametersUrlArray.push(`idTramo=${filters.stretchId}`);
      }

      if (filters.roadId) {
        parametersUrlArray.push(`idCtra=${filters.roadId}`);
      }

      if (filters.networkId) {
        parametersUrlArray.push(`idRed=${filters.networkId}`);
      }

      if (parametersUrlArray.length > 0) {
        parametersUrl = `?${parametersUrlArray.join('&')}`;
      }
    }

    return this.base.get<Extent>(`Geometria/GetExtent${parametersUrl}`);
  };

  /**
   * Geometry simplified
   * @returns
   */
  getSimplifiedGeometry = (): Observable<SimplifiedGeometry[]> =>
    this.http.get<{ idTramo: number; geometria: [number, number][] }[]>('../assets/fake/geometry/simplify.json').pipe(
      delay(3000),
      map((m) => {
        return m.map((k) => {
          const currentItem: SimplifiedGeometry = {
            geometry: k.geometria,
            tramoId: k.idTramo,
          };
          return currentItem;
        });
      })
    );
}
