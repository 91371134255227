import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from '../card/card.module';
import { ModalBaseComponent } from './modal-base/modal-base.component';
import { ModalBodyComponent } from './modal-body/modal-body.component';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';

@NgModule({
  declarations: [ModalComponent, ModalBaseComponent, ModalBodyComponent],
  imports: [CommonModule, OverlayModule, CardModule, TranslateModule],
  exports: [ModalComponent, ModalBodyComponent],
  providers: [ModalService],
})
export class ModalModule {}
