/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export interface OverlayCloseEvent<R> {
  type: 'backdrop' | 'close';
  data: R | undefined;
}

export class ModalOverlayRef<R = any, T = any> {
  closed$ = new Subject<OverlayCloseEvent<R>>();

  constructor(
    public overlay: OverlayRef,
    public data: T | undefined, // pass data to modal i.e. FormData,
    public closeOnBackdropClick = false
  ) {
    overlay.backdropClick().subscribe(() => {
      if (this.closeOnBackdropClick) {
        this._close('backdrop', undefined);
      }
    });
  }

  close(data?: R | undefined) {
    this._close('close', data);
  }

  private _close(type: 'backdrop' | 'close', data?: R | undefined) {
    this.overlay.dispose();
    this.closed$.next({
      type,
      data,
    });

    this.closed$.complete();
  }
}
