/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Extent, SimplifiedGeometry } from '../..';
import * as geometry from '../actions/geometry.actions';

export interface GeometryState {
  extent: Extent | undefined;
  simplified: SimplifiedGeometry[];
}

export const geometryInitialState: GeometryState = {
  extent: undefined,
  simplified: [],
};

export const getGeometryState = createFeatureSelector<GeometryState>('geometry');

export const getExtentState = createSelector(getGeometryState, (state: GeometryState) => state.extent);
export const getSimplifiedState = createSelector(getGeometryState, (state: GeometryState) => state.simplified);

const _geometryReducer = createReducer(
  geometryInitialState,
  on(geometry.loadExtent, (state) => state),
  on(geometry.loadedExtent, (state) => state),
  on(geometry.loadExtentSuccess, (state, { extent }) => ({
    ...state,
    extent: extent,
  })),
  on(geometry.loadExtentError, (state, { payload }) => ({
    ...state,
    error: payload,
  })),
  on(geometry.loadSimplifiedGeometry, (state) => state),
  on(geometry.loadedSimplifiedGeometry, (state) => state),
  on(geometry.loadSimplifiedGeometrySuccess, (state, { simplified }) => ({
    ...state,
    simplified,
  })),
  on(geometry.loadSimplifiedGeometrytError, (state, { payload }) => ({
    ...state,
    error: payload,
  }))
);

export function geometryReducer(state: GeometryState | undefined, action: Action): GeometryState {
  return _geometryReducer(state, action);
}
