import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { Graph3Component } from './graph3.component';

@NgModule({
  declarations: [Graph3Component],
  imports: [CommonModule, DxChartModule],
  exports: [Graph3Component],
})
export class Graph3Module {}
