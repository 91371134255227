import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonIconModule } from '../button-icon/button-icon.module';
import { DynamicTabsDirective } from './dynamic-tabs.directive';
import { ItemTabComponent } from './item-tab/item-tab.component';
import { ViewTabComponent } from './tab/view-tab.component';

@NgModule({
  declarations: [ViewTabComponent, ItemTabComponent, DynamicTabsDirective],
  imports: [CommonModule, ButtonIconModule],
  exports: [ViewTabComponent, ItemTabComponent],
})
export class ViewTabModule {}
