import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { AusGraphComponent } from './components/aus-graph/aus-graph.component';

@NgModule({
  declarations: [AusGraphComponent],
  imports: [CommonModule, AngularResizedEventModule],
  exports: [AusGraphComponent],
})
export class AuscultacionGraphModule {
  static forRoot(): ModuleWithProviders<AuscultacionGraphModule> {
    return {
      ngModule: AuscultacionGraphModule,
      providers: [],
    };
  }
}
