/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Config } from 'src/app/core/modules/config';
import { ConfigService } from 'src/app/core/modules/config/config.service';
import { UserInfo } from '..';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private configuration: Config | undefined;

  constructor(private config: ConfigService, private http: HttpClient, private token: TokenService) {}

  /**
   * Authenticated user information
   * @returns
   */
  info = (): Observable<UserInfo | null> => {
    this.configuration = this.config.get();
    if (!this.configuration) {
      return of(null);
    }
    return this.http.get<any>(`${this.configuration.auth_api_enpoint}/user/info`).pipe(
      map((m) => {
        const user: UserInfo = {
          email: m.email,
          id: m.id,
          fullname: `${m.name} ${m.apellidos}`,
        };
        return user;
      })
    );
  };

  /**
   * Current language of authenticated user
   * @returns
   */
  currentLanguage = (): string => {
    const payload = this.token.get();
    if (payload) {
      return payload.LangCode;
    }
    return 'en-US';
  };
}
