/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as turf from '@turf/turf';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import GeometryType from 'ol/geom/GeometryType';
import { default as CircleStyle } from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { FeatureMap, FeatureMapStyle } from '.';
import { getSimplifiedState } from '../../store/reducers';

@Injectable({
  providedIn: 'root',
})
export class RsMapService {
  simplified = this.store.pipe(select(getSimplifiedState));

  constructor(private store: Store<AppState>) {}

  generateFeatures = (features: FeatureMap[]): Observable<Feature[]> => {
    return this.simplified.pipe(
      map((m) => {
        const featuresStretch = this.groupBy(features, (feat) => feat.localization.tramoId);
        const allFeatures: Feature[] = [];
        featuresStretch.forEach((k, key) => {
          const geometryStretch = m.find((gs) => gs.tramoId === key);

          if (geometryStretch && geometryStretch.geometry.length > 0) {
            const lineaStretch = turf.lineString(geometryStretch.geometry);
            const lineDistance = turf.lineDistance(lineaStretch, {
              units: 'kilometers',
            });
            k.forEach((j: any) => {
              if (this.isPuntual(j)) {
                allFeatures.push(this.getPoint(lineaStretch, lineDistance, j));
              } else {
                const currentLine = this.getLine(lineaStretch, lineDistance, j);
                if (currentLine) {
                  allFeatures.push(currentLine);
                }
              }
            });
          }
        });
        return allFeatures;
      })
    );
  };

  generateFeaturesFromGeoJson = (geojson: any): Feature[] => {
    const allFeatures: Feature[] = [];
    const features = new GeoJSON().readFeatures(geojson, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });

    return features;
  };

  private isPuntual = (feat: FeatureMap): boolean => {
    const anyDistanceF = feat.localization.distanceF !== undefined && feat.localization.distanceF !== null;
    return !anyDistanceF || feat.localization.distanceF === feat.localization.distanceI;
  };

  private getPoint(line: turf.helpers.Feature<turf.helpers.LineString>, distance: number, feat: FeatureMap) {
    let pointDistance = feat.localization.distanceI / 1000.0;

    if (pointDistance > distance) {
      pointDistance = distance;
    }

    const point = turf.along(line, pointDistance, {
      units: 'kilometers',
    });

    const featurePoint = new GeoJSON().readFeature(point, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    const props = featurePoint.getProperties();
    props['data'] = feat.data;
    featurePoint.setProperties(props);

    // Set styles

    if (feat.style?.iconURL) {
      const icon = new Icon({
        src: feat.style?.iconURL,
        // the real size of your icon
        // size: [50, 50],
        // the scale factor
        scale: feat.style?.scale ? feat.style?.scale : 1,
      });

      const estilo = [
        new Style({
          image: icon,
        }),
      ];

      featurePoint.setStyle(estilo);
    } else {
      const circle = new CircleStyle({
        fill: new Fill({
          color: feat.style?.fillColor,
        }),
        radius: feat.style?.radius ? feat.style?.radius : 5,
        stroke: new Stroke({
          color: feat.style?.strokeColor,
          width: feat.style?.strokeWidth,
        }),
      });

      const estilo = [
        new Style({
          image: circle,
        }),
      ];
      featurePoint.setStyle(estilo);
    }

    return featurePoint;
  }

  private getLine(line: turf.helpers.Feature<turf.helpers.LineString>, distance: number, feat: FeatureMap) {
    const start =
      (feat.localization.distanceI <= feat.localization.distanceF
        ? feat.localization.distanceI
        : feat.localization.distanceF) / 1000.0;
    let stop =
      (feat.localization.distanceI <= feat.localization.distanceF
        ? feat.localization.distanceF
        : feat.localization.distanceI) / 1000.0;

    if (start > distance && stop > distance) {
      return null;
    }

    if (stop > distance) {
      stop = distance;
    }
    const lineaAcotada = turf.lineSliceAlong(line, start, stop, {
      units: 'kilometers',
    });

    const features = new GeoJSON().readFeature(lineaAcotada, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    const props = features.getProperties();
    props['data'] = feat.data;
    features.setProperties(props);

    const style = [
      new Style({
        stroke: new Stroke({
          color: feat.style?.fillColor,
          width:
            (feat.style && feat.style.strokeWidth && feat.style.strokeWidth < 5) ||
            !feat.style ||
            !feat.style.strokeWidth
              ? 5
              : feat.style?.strokeWidth,
        }),
      }),
    ];
    features.setStyle(style);

    return features;
  }

  public getStyle(geometryType?: GeometryType, properties?: FeatureMapStyle): Style[] {
    const style: Style[] = [];

    if (geometryType === 'Point') {
      if (properties?.iconURL) {
        const icon = new Icon({
          src: properties?.iconURL,
          scale: properties?.scale ? properties?.scale : 1,
        });

        style.push(
          new Style({
            image: icon,
          })
        );
      } else {
        const circle = new CircleStyle({
          fill: new Fill({
            color: properties?.fillColor,
          }),
          radius: properties?.radius ? properties?.radius : 5,
          stroke: new Stroke({
            color: properties?.strokeColor,
            width: properties?.strokeWidth,
          }),
        });

        style.push(
          new Style({
            image: circle,
          })
        );
      }
    } else if (geometryType === 'LineString') {
      style.push(
        new Style({
          stroke: new Stroke({
            color: properties?.fillColor,
            width:
              (properties && properties.strokeWidth && properties.strokeWidth < 5) ||
              !properties ||
              !properties.strokeWidth
                ? 5
                : properties?.strokeWidth,
          }),
        })
      );
    }

    return style;
  }

  private groupBy(list: any[], keyGetter: (arg0: any) => any) {
    const map = new Map();

    if (!list || list === undefined || list.length === 0) {
      return map;
    }
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
}
