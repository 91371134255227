/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { loadUserInfo } from 'src/app/shared/store/actions';

@Component({
  selector: 'rs-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  userSuscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private token: TokenService,
    private spinner: SpinnerService,
    private store: Store<AppState>
  ) {}

  ngOnDestroy(): void {
    this.userSuscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.spinner.open();
    this.userSuscription = this.store.select('user').subscribe((m) => {
      this.spinner.close();
      if (m.user) {
        this.router.navigate(['/']);
      }
    });

    this.route.queryParams.subscribe((params) => {
      const refreshToken = params['refresh'];
      if (refreshToken) {
        this.token.refresh(refreshToken).subscribe(() => {
          this.store.dispatch(loadUserInfo());
        });
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
