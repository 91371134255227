/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BaseService } from './generics/base-service.service';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { ErrorHandlingInterceptorService } from './interceptors/error-handling-interceptor.service';
import { HeadersInterceptorService } from './interceptors/headers-interceptor.service';
import { RequestInterceptorService } from './interceptors/request-interceptor.service';
import { ConfigModule } from './modules/config/config.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ConfigModule.forRoot()],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        BaseService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptorService,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptorService,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeadersInterceptorService,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorHandlingInterceptorService,
          multi: true,
        },
      ],
    };
  }
}
