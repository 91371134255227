/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '.';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config: Config | undefined;
  private readonly path = './assets/config/config.json';
  constructor(private http: HttpClient) {}

  load() {
    return this.http
      .get<Config>(`${this.path}`)
      .toPromise()
      .then((config: Config) => {
        this._config = config;
        return config;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  get() {
    return this._config;
  }
}
