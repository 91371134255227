/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, createReducer, on } from '@ngrx/store';
import { UserInfo } from '../..';
import * as user from '../actions/user.actions';

export interface State {
  user: UserInfo | null;
}

export const initialState: State = {
  user: null,
};

const _userReducer = createReducer(
  initialState,
  on(user.loadUserInfo, (state) => state),
  on(user.loadUserInfoSuccess, (state, { user }) => ({
    ...state,
    user: user ? { ...user } : null,
  })),
  on(user.loadUserInfoError, (state, { payload }) => ({
    ...state,
    error: payload,
  }))
);

export function userReducer(state: State | undefined, action: Action) {
  return _userReducer(state, action);
}
