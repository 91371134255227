import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RsBaseMapComponent } from './components/rs-base-map/rs-base-map.component';
import { RsGeometryMapComponent } from './components/rs-geometry-map/rs-geometry-map.component';
import { RsMapService } from './rs-map.service';

@NgModule({
  declarations: [RsGeometryMapComponent, RsBaseMapComponent],
  imports: [CommonModule],
  exports: [RsGeometryMapComponent],
  providers: [RsMapService],
})
export class RsMapModule {}
