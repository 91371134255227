import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { BodyComponent } from './body/body.component';
import { CardComponent } from './card.component';
import { HeaderComponent } from './header/header.component';

const COMPONENTS = [CardComponent, HeaderComponent, BodyComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, DxLoadPanelModule],
  exports: [...COMPONENTS],
})
export class CardModule {}
