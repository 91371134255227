/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Config } from 'src/app/core/modules/config';
import { ConfigService } from 'src/app/core/modules/config/config.service';
import { Authentication, TOKEN_NAME } from '..';
import { TokenService } from './token.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private configuration: Config | undefined;

  constructor(private config: ConfigService, private http: HttpClient, private token: TokenService) {}

  /**
   *  User login
   * @param user username
   * @param pass password
   * @returns
   */
  login = (user: string = '', pass: string): Observable<Authentication | null> => {
    this.configuration = this.config.get();

    const body = new URLSearchParams();
    body.set('username', user);
    body.set('password', pass);
    body.set('grant_type', 'password');
    body.set('client_id', 'api');
    body.set('hostId', this.configuration ? this.configuration.host : '');

    if (!this.configuration) {
      return of(null);
    }
    return this.http.post<Authentication>(`${this.configuration.auth_api_enpoint}/connect/token`, body.toString()).pipe(
      tap({
        // eslint-disable-next-line prettier/prettier
        next: (m) => localStorage.setItem(TOKEN_NAME, JSON.stringify(m)),
      })
    );
  };

  /**
   * Close session
   */
  logout = () => {
    this.config.load().then((m) => {
      this.configuration = this.config.get();
      console.log(this.configuration);
      localStorage.removeItem(TOKEN_NAME);
      if (this.configuration) {
        window.location.href = this.configuration.login;
      }
    });
  };

  /**
   * Is user authenticated
   * @returns
   */
  isAuthenticated = (): boolean => {
    return this.token.getAuthData() !== null;
  };
}
