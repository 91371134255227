/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../modules/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService {
  constructor(private config: ConfigService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (httpRequest.url.endsWith('.json')) {
      return next.handle(httpRequest);
    }

    const globalConfig = this.config.get();

    if (!httpRequest.url.startsWith('./') && !httpRequest.url.startsWith('http')) {
      httpRequest = httpRequest.clone({
        url: `${globalConfig?.api_endpoint}${httpRequest.url}`,
      });
    }

    return next.handle(httpRequest);
  }
}
