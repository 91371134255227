/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { JwtPayload } from 'jwt-decode';

/* eslint-disable prettier/prettier */
export interface Authentication {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export interface UserInfo {
  id: string;
  email: string;
  fullname: string;
}

export interface PayLoad extends JwtPayload {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LangCode: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RoleToken: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NameToken: string;
}

export const TOKEN_NAME = 'currentUser';

export interface Extent {
  minLat: number;
  minLon: number;
  maxLat: number;
  maxLon: number;
}

export interface SimplifiedGeometry {
  tramoId: number;
  geometry: [number, number][];
}

export interface BaseStore {
  loading: boolean;
  loaded: boolean;
  data?: any;
}

export interface DynamicObject {
  [key: string]: any;
}

export interface Geometry {
  pkHito?: number;
  pkDist?: number;
  latitude?: number;
  longitude?: number;
  x?: number;
  y?: number;
  z?: number;
  idTramo: number;
  idZona?: number;
  distancia: number;
  distanciaRaw?: number; // distancia original sin redondear
  imagenes: {
    [key: string]: string;
  };
  roll?: number;
  pitch?: number;
  heading?: number;
  pkFormat?: string;
}

export interface Stretch {
  id: number;
  name: string;
  roadId: number;
}
