/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { Extent, SimplifiedGeometry } from '../..';

export const loadedExtent = createAction('[GeometryExtent] loadedExtent');
export const loadExtent = createAction('[GeometryExtent] loadExtent');
export const loadExtentSuccess = createAction(
  '[GeometryExtent] loadExtentSuccess',
  props<{ extent: Extent | undefined }>()
);
export const loadExtentError = createAction('[GeometryExtent] loadExtentError', props<{ payload: any }>());

export const loadedSimplifiedGeometry = createAction('[SimplifiedGeometry] loadedSimplifiedGeometry');
export const loadSimplifiedGeometry = createAction('[SimplifiedGeometry] loadSimplifiedGeometry');
export const loadSimplifiedGeometrySuccess = createAction(
  '[SimplifiedGeometry] loadSimplifiedGeometrySuccess',
  props<{ simplified: SimplifiedGeometry[] }>()
);
export const loadSimplifiedGeometrytError = createAction(
  '[SimplifiedGeometry] loadSimplifiedGeometryError',
  props<{ payload: any }>()
);
