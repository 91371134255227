/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppState } from 'src/app/app.reducer';
import { UserInfo } from '../..';
import { AuthService } from '../../services/auth.service';
import { MODAL_DATA } from '../modal/modal.service';

@Component({
  selector: 'rs-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit, OnDestroy {
  userSuscription: Subscription | undefined;
  user: UserInfo | null = null;
  pass = '';

  constructor(private store: Store<AppState>, private auth: AuthService, private inj: Injector) {}

  ngOnDestroy(): void {
    this.userSuscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.userSuscription = this.store.select('user').subscribe((m) => {
      this.user = m.user;
    });
  }

  close = (): void => {
    this.inj.get(MODAL_DATA).events.close();
  };

  authentication = (): void => {
    this.auth.login(this.user?.email, this.pass).subscribe(
      () => {
        this.close();
      },
      () => {
        this.pass = '';
      }
    );
  };
}
