/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/core/generics/base-service.service';

const controller = 'Carretera';

@Injectable({
  providedIn: 'root',
})
export class RoadService {
  constructor(private base: BaseService) {}

  getAll = (): Observable<{ id: number; name: string; zones: number[] }[]> => {
    return this.base.get<any>(`${controller}/GetAll`).pipe(
      map((k: any[]) => {
        return k.map((j) => {
          const cast: { id: number; name: string; zones: number[] } = {
            id: j.idCtra,
            name: j.carretera,
            zones: j.zonas,
          };
          return cast;
        });
      })
    );
  };
}
