import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LandingComponent } from './features/landing/landing.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'landing', component: LandingComponent, data: { roles: [''] } },
      {
        path: '',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/base-page/base-page.module').then((m) => m.BasePageModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
