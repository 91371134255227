/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService, private translate: TranslateService) {}
  /**
   * Show success nofification
   * @param message
   * @param title
   * @returns
   */
  success = (message: string, title?: string): ActiveToast<any> =>
    title
      ? this.toastr.success(this.translate.instant(message), this.translate.instant(title))
      : this.toastr.success(this.translate.instant(message));
  /**
   * Show error notification
   * @param message
   * @param title
   * @returns
   */
  error = (message: string, title?: string): ActiveToast<any> =>
    title
      ? this.toastr.error(this.translate.instant(message), this.translate.instant(title))
      : this.toastr.error(this.translate.instant(message));
  /**
   * Show info notification
   * @param message
   * @param title
   * @returns
   */
  info = (message: string, title?: string): ActiveToast<any> =>
    title
      ? this.toastr.info(this.translate.instant(message), this.translate.instant(title))
      : this.toastr.info(this.translate.instant(message));
  /**
   * Show warning notification
   * @param message
   * @param title
   * @returns
   */
  warning = (message: string, title?: string): ActiveToast<any> =>
    title
      ? this.toastr.warning(this.translate.instant(message), this.translate.instant(title))
      : this.toastr.warning(this.translate.instant(message));
}
