<div class="bg-white py-3 px-4 border rounded-3" style="width: 20vw">
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center flex-fill flex-column">
      <img src="assets/images/lock.svg" style="width: 3.5vw" />

      <span class="mt-4 fs-5" style="color: red">{{'sesioncaducada' | translate}}</span>

      <div class="mt-4">
        <i class="icofont-ui-user fs-5"></i><span class="ms-2 fs-6 fw-bold">{{user?.fullname}}</span>
      </div>
      <input
        class="form-control w-100 mt-3"
        type="password"
        [(ngModel)]="pass"
        placeholder="Introduzca la contraseña."
      />
    </div>
  </div>
  <div class="d-flex align-items-center flex-fill flex-column mt-4">
    <div class="d-flex">
      <button class="btn btn-danger" (click)="close()">{{'salir' | translate }}</button>
      <button [disabled]="!pass" class="btn btn-primary ms-2" (click)="authentication()">
        {{'entrar' | translate }}
      </button>
    </div>
  </div>
</div>
