import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { ModalModule } from '../../components/modal/modal.module';
import { Graph2Component } from './graph2.component';

@NgModule({
  declarations: [Graph2Component],
  imports: [CommonModule, TranslateModule, ModalModule, DxChartModule, DxPieChartModule, DxTabPanelModule],
  exports: [Graph2Component],
})
export class Graph2Module {}
