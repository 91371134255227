/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResponse } from '..';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(private http: HttpClient) {}

  //#region [ Public ]
  get<T>(url: string): Observable<T> {
    return this.http.get<BaseResponse>(url).pipe(
      map((m: BaseResponse) => {
        if (!m.succeeded) {
          throw new Error('The request was not successful.');
        }
        return m.data;
      }),
      catchError(this.handleError)
    );
  }

  post<T>(
    url: string,
    data?: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
    }
  ): Observable<T> {
    return this.http.post<BaseResponse>(url, data, options).pipe(
      map((m: BaseResponse) => {
        if (!m.succeeded) {
          throw new Error('The request was not successful.');
        }
        return m.data;
      }),
      catchError(this.handleError)
    );
  }

  put<T>(url: string, data?: any): Observable<T> {
    return this.http.put<BaseResponse>(url, data).pipe(
      map((m: BaseResponse) => {
        if (!m.succeeded) {
          throw new Error('The request was not successful.');
        }
        return m.data;
      }),
      catchError(this.handleError)
    );
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<BaseResponse>(url).pipe(
      map((m: BaseResponse) => {
        if (!m.succeeded) {
          throw new Error('The request was not successful.');
        }
        return m.data;
      }),
      catchError(this.handleError)
    );
  }

  //#endregion

  //#region [ Private ]
  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error code: ${error.status}, ` + `mensage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  //#endregion
}
